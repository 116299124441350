window.transWithPlaceholders = function (translation, options) {
    if (options.length === 0) {
        return translation;
    }

    for (let i = 0; i < options.length; i++) {
        const value = options[i];
        translation = translation.replace("{" + i + "}", value);
    }

    return translation;
};
