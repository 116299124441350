window.displayToast = function (message, options) {
    const html = `
        <strong>${message}</strong>
    `;

    const toast = document.createElement("div");
    toast.classList.add("js-toast");

    if ("type" in options && options.type) {
        toast.classList.add(options.type);
    }

    toast.innerHTML = html;
    document.body.appendChild(toast);

    setTimeout(() => {
        document.body.removeChild(toast);
    }, 5000);
};
