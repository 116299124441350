window.formatPrice = function(price, format, numberFormat = null){
    if(numberFormat === null){
        numberFormat = getLocale();
    }
    
    const options = numberFormats[numberFormat][format];
    const formatter = new Intl.NumberFormat(numberFormat, options);

    return formatter.format(price);
}

window.numberFormats = {
    de: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      currencyNoComma: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    },
    en: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      currencyNoComma: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    },
    fr: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      currencyNoComma: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    },
    ch: {
      currency: {
        style: 'currency',
        currency: 'CHF',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      currencyNoComma: {
        style: 'currency',
        currency: 'CHF',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    },
}