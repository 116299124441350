import Keycloak from '@modules/keycloak-js';

export default class SsoPlugin {
    constructor() {
        //listen on custom window event user-loaded
        window.addEventListener('user-loaded', async (event) => {
            await SsoPlugin.init(event.detail)
        })
    }

    /**
     * Initialize the plugin
     */
    static async init(user) {
        SsoPlugin.isLoggedIn = !!(user)
        SsoPlugin.body = document.body;

        SsoPlugin.fetchArgs = {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        // await SsoPlugin.checkSsoSession() // Todo: Check why i get redirected multiple times
    }

    /**
     * Check for active SSO session
     */
    static async checkSsoSession() {
        SsoPlugin.keycloak = new Keycloak(
            {
                url: window.sso.host,
                realm: window.sso.realm,
                clientId: window.sso.clientId,
            },
        );

        SsoPlugin.keycloak.init({
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: window.sso.silentCheck,
        }).then(async function (authenticated) {
            const validated = await SsoPlugin.validateSsoUser(SsoPlugin.keycloak.token)

            if (SsoPlugin.isLoggedIn) {
                if (
                    authenticated
                    && SsoPlugin.keycloak.token
                    && !validated
                ) {
                    window.location = window.sso.logout
                } else {
                    window.location = window.sso.logout
                }
            } else if (authenticated && SsoPlugin.keycloak.token && validated) {
                window.location = window.sso.login
            }
        }).catch(function () {
        });
    }

    /**
     * Validate the user in Pimcore
     * @param token
     * @returns {Promise<boolean>}
     */
    static async validateSsoUser(token) {
        let validate = false

        if(!token) return validate

        await fetch(
            window.location.origin + '/sso/validate/' + token,
            SsoPlugin.fetchArgs).then(res => res.json()).then(res => {
            validate = res.success
        }).catch(err => {
        })

        return validate
    }
}

// Initialize the plugin
new SsoPlugin();