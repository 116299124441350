function displayPersonalizedAreabricks(user) {

    const elements = document.querySelectorAll("[data-personal-areabrick]");

    if (!elements) {
        return;
    }

    elements.forEach(async element => {
        const groups = element.getAttribute('data-personal-groups').split(',').map(id => parseInt(id));
        const segments = element.getAttribute('data-personal-segments').split(',').map(id => parseInt(id));
        const areabrickId = element.getAttribute('data-personal-areabrick');
        
        if(segmentOrGroupMatches(user, groups, segments)){
            const response = await fetch('/api/lindnertraktoren/personal-areabrick/' + areabrickId);
            const json = await response.json();
            if(json.success){
                insertAndExecute(element, json.data.html)
                document.dispatchEvent(new Event('alpine:init'));
            }
        }
    })
}

function replacePlaceholders(user) {

    const elements = document.querySelectorAll("[data-personal-placeholder]");

    if (!elements) {
        return;
    }

    elements.forEach(async element => {
        const groups = element.getAttribute('data-personal-groups').split(',').map(id => parseInt(id));
        const segments = element.getAttribute('data-personal-segments').split(',').map(id => parseInt(id));
        const snippetId = element.getAttribute('data-personal-placeholder');
        
        if(segmentOrGroupMatches(user, groups, segments)){
            const response = await fetch('/api/lindnertraktoren/personal-snippet/' + snippetId);
            const json = await response.json();
            if(json.success){
                insertAndExecute(element, json.data.html)
                document.dispatchEvent(new Event('alpine:init'));
            }
        }
    })
}

function segmentOrGroupMatches(user, groups, segments){
    if(user.groups && user.groups.length > 0){
        for (let i = 0; i < user.groups.length; i++) {
            if(groups.includes(user.groups[i])){
                return true;
            }
        }
    }

    if(user.segments.merged.length > 0){
        for (let i = 0; i < user.segments.merged.length; i++) {
            if(segments.includes(user.segments.merged[i])){
                return true;
            }
        }
    }
    
    return false;
}

function insertAndExecute(element, content) {
    element.innerHTML = content;
    element.getElementsByTagName("script").forEach(script => {
        eval(script.innerHTML);
    })
}

async function getTargetGroups(){
    const response = await fetch('/api/lindnertraktoren/user/target-groups');
    const json = await response.json();
    if(json.success){
        return json.data.groups;
    }
}

window.addEventListener('user-loaded', async (event) => {
    const user = event.detail;
    
    if(user){
        user.groups = await getTargetGroups()
        replacePlaceholders(user);
        displayPersonalizedAreabricks(user);
    }
})
