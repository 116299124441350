window.sanitizeString = function (str) {
    if (!str || typeof str !== "string") return "";

    return str
        .replace(" ", "-")
        .replace("ä", "ae")
        .replace("ö", "oe")
        .replace("ü", "ue")
        .toLowerCase();
};
